import { Configuration } from "@azure/msal-browser";

export const MSAL_CONFIG: Configuration = {
  auth: {
    // temp until app reg has admin grants
    clientId: "de50ce2f-c6c7-47c5-9928-42ae19d8b7ac",
    authority: "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
    redirectUri: "https://localhost:3000",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  telemetry: {
    application: {
      appName: "DDB - Excel",
      appVersion: "1.0.0",
    },
  },
};
