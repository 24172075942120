/*global CustomFunctions */
import { ParameterSchema, SelectedEntryRefSchemaStatusEnum } from "@ddb/parameter-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { DdbEnvironment, parameterService, unpaginate, validateEnvironment } from "../../shared/ddb";
import { isGuid } from "../../shared/helpers";

class ParametersBatcher extends Batcher<ParameterSchema> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService(environment);
    return await toMap(
      unpaginate(
        (after) => client.getAllParameters({ after, parameterId: keys }),
        (data) => data.data.parameters,
        (data) => data.data.paging?.cursors?.after
      ),
      (parameter) => parameter.id,
      (parameter) => parameter
    );
  }
}

const parametersBatcher = new ParametersBatcher(10);

/**
 * Gets DDB parameter value.
 * @customfunction PARAMETER.PARAMETER_VALUE
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter value.
 * @cancelable
 */
export async function parameterValue(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<any> {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  invocation.onCanceled = () => parametersBatcher.cancel(environment, parameterId);
  const parameter = await parametersBatcher.enqueue(environment, parameterId);
  return parameter.selected_entry?.values?.at(0)?.value ?? "";
}

/**
 * Gets DDB parameter source ID.
 * @customfunction PARAMETER.PARAMETER_SOURCE_ID
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter source ID.
 * @cancelable
 */
export async function parameterSourceId(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<string> {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  invocation.onCanceled = () => parametersBatcher.cancel(environment, parameterId);
  const parameter = await parametersBatcher.enqueue(environment, parameterId);
  return parameter.selected_entry?.source?.id ?? "";
}

/**
 * Gets DDB parameter status.
 * @customfunction PARAMETER.PARAMETER_STATUS
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter status.
 * @cancelable
 */
export async function parameterStatus(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<string> {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  invocation.onCanceled = () => parametersBatcher.cancel(environment, parameterId);
  const parameter = await parametersBatcher.enqueue(environment, parameterId);
  const status: SelectedEntryRefSchemaStatusEnum = parameter.selected_entry?.status ?? "unanswered";
  return status;
}

/**
 * Gets DDB parameter unit symbol.
 * @customfunction PARAMETER.PARAMETER_UNIT_SYMBOL
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter unit symbol.
 * @cancelable
 */
export async function parameterUnitSymbol(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<string> {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  invocation.onCanceled = () => parametersBatcher.cancel(environment, parameterId);
  const parameter = await parametersBatcher.enqueue(environment, parameterId);
  return parameter.selected_entry?.values?.at(0)?.unit?.symbol ?? "";
}

CustomFunctions.associate("PARAMETER.PARAMETER_VALUE", parameterValue);
CustomFunctions.associate("PARAMETER.PARAMETER_SOURCE_ID", parameterSourceId);
CustomFunctions.associate("PARAMETER.PARAMETER_STATUS", parameterStatus);
CustomFunctions.associate("PARAMETER.PARAMETER_UNIT_SYMBOL", parameterUnitSymbol);