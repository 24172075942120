import { DdbEnvironment } from "./ddb";
import { chunk, union } from "./helpers";

interface Entry<T> {
  key: string;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
  promise: Promise<T>;
}

export abstract class Batcher<T> {
  _entries: Record<DdbEnvironment, Entry<T>[]>;
  batchSize: number;
  _timeouts: { [key in DdbEnvironment]?: ReturnType<typeof setTimeout> };

  constructor(batchSize: number) {
    this.batchSize = batchSize;
    this._entries = {
      develop: [],
      sandbox: [],
      production: [],
    };
    this._timeouts = {};
  }

  enqueue(environment: DdbEnvironment, key: string): Promise<T> {
    const entries = this._entries[environment];

    // Executor is called in promise constructor, so these are guaranteed to be set
    let resolve: (value: T | PromiseLike<T>) => void = undefined!;
    let reject: (reason?: any) => void = undefined!;

    const promise = new Promise<T>((resolve_, reject_) => {
      resolve = resolve_;
      reject = reject_;
    });

    entries.push({ key, resolve, reject, promise });
    const interval = this._timeouts[environment];
    if (entries.length >= this.batchSize) {
      clearTimeout(interval);
      delete this._timeouts[environment];
      this._processBatch(environment);
    } else if (interval === undefined) {
      this._timeouts[environment] = setTimeout(() => {
        this._processBatch(environment);
        delete this._timeouts[environment];
      }, 100);
    }
    return promise;
  }

  async _processBatch(environment: DdbEnvironment) {
    const entries = this._entries[environment];

    try {
      const resultMaps = await Promise.allSettled(
        chunk([...new Set(entries.map((entry) => entry.key))], this.batchSize).map((keys) =>
          this._sendRequest(environment, keys)
        )
      );

      const results = union(resultMaps.filter((result) => result.status === "fulfilled").map((result) => result.value));
      for (const entry of entries) {
        const result = results.get(entry.key);
        if (result === undefined) {
          entry.reject(new Error(`No result for key ${entry.key}`));
        } else {
          entry.resolve(result);
        }
      }
    } catch (error) {
      for (const entry of entries) {
        entry.reject(error);
      }
    }

    entries.length = 0;
  }

  async _sendRequest(environment: DdbEnvironment, keys: string[]): Promise<Map<string, T>> {
    try {
      const results = await this._getResults(environment, keys);
      return results;
    } catch (error) {
      const half = Math.ceil(keys.length / 2);
      if (half <= 1) {
        throw error;
      }
      const [left, right] = [keys.slice(0, half), keys.slice(half)];
      const results = await Promise.allSettled([
        this._sendRequest(environment, left),
        this._sendRequest(environment, right),
      ]);
      return union(results.filter((result) => result.status === "fulfilled").map((result) => result.value));
    }
  }

  abstract _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, T>>;

  cancel(environment: DdbEnvironment, key: string) {
    const entries = this._entries[environment];
    const index = entries.findIndex((entry) => entry.key === key);
    if (index !== -1) {
      const entry = entries[index];
      entry.reject(new Error("Canceled"));
      entries.splice(index, 1);
    }
  }
}
